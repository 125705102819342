import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Scrollbar } from "react-scrollbars-custom";
import { CustomButton } from "../../components/Button";
import { CheckText } from "../../components/CheckText";
import OpenAI  from "openai";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { ReactComponent as LogoSvg } from "../../assets/images/logo.svg";
import { saveGoal_10F, saveDeadline_10F } from "../../redux/actions";
import store from "../../redux/store";
import axios from "axios";
import {
  StyledModalText1,
  StyledChatHistoryItem,
  StyledChatLogo,
  StyledModalContent,
  StyledChatHistoryItemText,
  StyledModalText2,
} from "./components";

import {
  StyledChat,
  StyledChatContent,
  ChatSidebar,
  ChatInput,
  StyledChatFooter,
} from "../../components/Chat";
import AWS from "aws-sdk";
//import { PollyClient, SynthesizeSpeechCommand } from "@aws-sdk/client-polly";

import * as API from "../../api";

import { Setting } from "../../config";

const openai = new OpenAI({
  apiKey: Setting.openaiKey,
  dangerouslyAllowBrowser: true
});

export const integrationQues = [
  "How has this process helped you in regards to… [TRIGGER]?",
  "What are you more conscious of now than before this process?",
  "What is your new intention in relation to…[TRIGGER]?",
  "What is the first action (big or small) that you can commit to now to help you realize this intention?",
  "When can you take that action?",
];

type History = {
  isBot: boolean;
  text: string;
};

const awsPollyCredentials = {
  accessKeyId: Setting.awsAccessKeyId!,
  secretAccessKey: Setting.awsSecretAccessKey!,
  region: "us-east-1",
};

AWS.config.credentials = awsPollyCredentials;
AWS.config.region = "us-east-1";

const polly = new AWS.Polly();

const checkYes = (text: string) => {
  let str: string = text.toLowerCase();
  if (str === "yes") return 1;
  return 0;
};

const checkNo = (text: string) => {
  let str: string = text.toLowerCase();
  if (str === "no") return 1;
  return 0;
};

const checkNothing = (text: string) => {
  let str: string = text.toLowerCase();
  if (
    str === "nothing" ||
    str === "none" ||
    str === "nothing else" ||
    str === "no" ||
    str === "nope"
  )
    return 1;
  return 0;
};

const checkEmptiness = (text: string) => {
  let str: string = text.toLowerCase();
  if (
    str.indexOf("nothing") !== -1 ||
    str.indexOf("empty") !== -1 ||
    str.indexOf("emptiness") !== -1 ||
    str.indexOf("nothingness") !== -1
  )
    return 1;
  return 0;
};

const checkSame = (text: string) => {
  let str: string = text.toLowerCase();
  if (str === "same" || str === "same thing" || str === "the same") return 1;
  return 0;
};

const checkDone = (text: string) => {
  let str: string = text.toLowerCase();
  if (str.indexOf("done") !== -1 || str.indexOf("okay") !== -1) return 1;
  return 0;
};

const checkNegative = (text: string) => {
  let str: string = text.toLowerCase();
  if (str === "negative") return 1;
  if (str === "positive" || str === "neutral") return 0;

  return -1;
};

const checkStart = (text: string) => {
  let str: string = text.toLowerCase();
  if (str.indexOf("start") !== -1) return 1;
  return 0;
};


const checkPositiveEmotion = async (text: string) => {
  try {
    const response = await axios.post(
      `https://api.apyhub.com/ai/text/sentiment/analysis`,
      {
        text: text,
        language: 'en',
        requested_service: 'ApyHub',
      },
      {
        headers: {
          'apy-token': 'APY0PYft1EtQahrQxBgUDUK7o9doC4AqWEQZOCAifbJwqnQsVnZ2u1l3GOR6SaPK1',
          'Content-Type': 'application/json'
        },
      }
    );
    if (response.data.data?.apyhub?.confidenceScores) {
      let item = response.data.data?.apyhub?.confidenceScores;
      if (item.negative > item.neutral && item.negative > item.positive) {
        return 0;
      }
    }
  } catch (error) {

  }
  return 1;
  /*
  const prompt =
    "You are a sentiment detection robot. You will tell me about  emotion that it's positive, neutral or negative. This is the emotion: '" +
    text +
    "'. if you are unsure, just give me your best guess. only respond with one of the following 'positive', 'neutral', and 'negative'";

  const chatCompletion: any = await openai.chat.completions.create({
    messages: [{ role: 'user', content: prompt }],
    model: 'gpt-4',
  });

  console.log(chatCompletion);

  const res: string = chatCompletion.choices[0].message.content;

  if (res.indexOf("negative") === -1 && res.indexOf("Negative") === -1)
    return 1;
  return 0;
  */

  /*
  const completion = await openai.createCompletion({
    model: "text-davinci-003",
    prompt: prompt,
  });
  const res: string = completion.data.choices[0].text!;

  if (res.indexOf("negative") === -1 && res.indexOf("Negative") === -1)
    return 1;
  return 0;
  */
};

const checkGoalSpiritualState = async (text: string) => {
  const prompt =
    `I'm going to send you a statement with a goal and I want you to extract the goal only. Remove subjects. The extracted goal should always start with "to". If the original statement is already formatted correctly, that's okay.

If there isn't a well defined goal, I may be in a spiritual state. A spiritual state is defined as when I have no conscious goal. I will typically describe it with words like: no goals, nothing, emptiness, i can't think of anything else, i don't know, i'm not sure, and so on. If you believe I am in a spiritual state, output with "SPIRITUAL STATE"

Examples:

Statement: It wants to protect me
Output: to protect me

Statement: my aspect wants me to love to love others more
Output: to love others more

Statement: for me to be authentic
Output: to be more authentic

Statement: to travel and see the world
Output: to travel and see the world

Statement: nothing else
Output: SPIRITUAL STATE

Statement: I can't think of anything
Output: SPIRITUAL STATE

Statement: I don't want anything
Output: SPIRITUAL STATE

Only respond with the extracted goal or "SPIRITUAL STATE". DO NOT add any additional commentary or remarks. DO NOT add "Output:" when response.

Here is the statement: '` +
    text +
    "'";

  const chatCompletion:any = await openai.chat.completions.create({
    messages: [{ role: 'user', content: prompt }],
    model: 'gpt-4',
  });

  console.log(chatCompletion);

  const res: string = chatCompletion.choices[0].message.content;

  console.log("spiritualresponse:", res);

  return res;
};

const useForceUpdate = () => {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here
  // is better than directly setting `setValue(value + 1)`
};

export default function ChatBox() {
  const user = useSelector((state: any) => state.auth.user);
  type AppDispatch = typeof store.dispatch;
  const useAppDispatch: () => AppDispatch = useDispatch;
  const dispatch = useAppDispatch();

  const [tipAdded, setTipAdded] = useState(false);
  const [isSatori, setIsSatori] = useState(false);
  const [inputDisable, setInputDisable] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [method, setMethod] = useState("");
  const [chatHistory, setChatHistory] = useState<History[]>([]);
  const [step, setStep] = useState(0);
  const [scrollBarObj, setScrollBarObj] = useState<Scrollbar | null>(null);
  const [purchaseModalShow, setPurchaseModalShow] = useState(false);

  const [prevQuestion, setPrevQuestion] = useState("");
  const [processTipsShow, setProcessTipsShow] = useState(true);
  const [processTimer, setProcessTimer] = useState(true);

  const [finished, setFinished] = useState(0);
  const [slowCnt, setSlowCnt] = useState(0);
  const [needTimer, setNeedTimer] = useState(false);
  const [isResume, setIsResume] = useState(false);

  const [timerObj, setTimerObj] = useState<null | ReturnType<
    typeof setTimeout
  >>(null);
  const responseTime = 18000;

  //satori trainer
  const [name, setName] = useState("");
  const [associationCnt, setAssociationCnt] = useState(0);
  const [associationRandom, setAssociationRandom] = useState(3);
  const [awareCnt, setAwareCnt] = useState(0);
  const [awareRandom, setAwareRandom] = useState(2);
  const [emptyCnt, setEmptyCnt] = useState(0);
  const [emptyCnt_N, setEmptyCnt_N] = useState(0);
  const [positiveMeCnt, setPositiveMeCnt] = useState(0);

  //satori2
  const [concept, setConcept] = useState("");
  const [conceptOpp, setConceptOpp] = useState("");

  //satori3
  const [topic, setTopic] = useState("");
  const [emotion, setEmotion] = useState("");
  const [meCnt, setMeCnt] = useState(0);
  const [meInput, setMeInput] = useState("");

  //satori4
  const [emotion2, setEmotion2] = useState("");

  //dp2
  const [positiveCnt, setPositiveCnt] = useState(0);

  //ip standard
  const [identity, setIdentity] = useState("");
  const [identity2, setIdentity2] = useState("");

  //ip advanced
  const [goal, setGoal] = useState("");
  //10f process
  const [deadline, setDeadline] = useState("");
  const [failReason, setFailReason] = useState("");

  // Aspect Goal
  const [aspectGoals, setAspectGoals] = useState<string[]>([]);
  const [aspectHighestGoal, setAspectHighestGoal] = useState("");

  const [prevSourceNode, setPrevSourceNode] =
    useState<AudioBufferSourceNode | null>(null);

  /*
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(null);
  const audioCtx = new window.AudioContext();

  useEffect(() => {
    if (!audioBuffer) return;

    if (prevSourceNode) {
      console.log("stop");
      prevSourceNode.stop();
    }

    const sourceNode = audioCtx.createBufferSource();
    sourceNode.loop = false;
    sourceNode.buffer = audioBuffer;
    sourceNode.connect(audioCtx.destination);

    sourceNode.start(0);
    setPrevSourceNode(sourceNode);
  }, [audioBuffer]);
*/
  const [audioContexts, setAudioContexts] = useState<AudioContext[]>([]);

  const checkMe = (text: string) => {
    let str: string = text.toLowerCase();
    if (str.indexOf("also ") === 0) str = str.substring(5);

    if (
      (str === name.toLowerCase() && name !== "") ||
      str === "myself" ||
      str === "just myself" ||
      str === "i feel myself" ||
      str === "just me" ||
      str === "only me" ||
      str === "i can only feel myself" ||
      str === "i am" ||
      str === "i" ||
      str === "me" ||
      str === "only myself" ||
      str === "i am me" ||
      str === "i am i" ||
      str === "only i"
    )
      return 1;
    return 0;
  };

  function arrayBufferToAudioBuffer(
    arrayBuffer: any,
    context: any
  ): Promise<AudioBuffer> {
    return new Promise((resolve, reject) => {
      if (context) {
        if (
          Object.prototype.toString.call(context) !== "[object AudioContext]"
        ) {
          throw new TypeError("`context` must be an AudioContext");
        }
      } else {
        if (typeof window !== "undefined") {
          context = new window.AudioContext();
        }
      }

      context.decodeAudioData(
        arrayBuffer,
        function (data: any) {
          resolve(data);
        },
        reject
      );
    });
  }

  async function textToSpeech(txt: string, speed = 0.8, volume = 1) {
    //return;
    if (parseInt(Setting.voice!) !== 1 || (user && !user.voiceEnable)) return;

    try {
      let params = {
        Engine: "neural",
        OutputFormat: "mp3",
        SampleRate: "8000",
        Text: "<speak>" + txt + "</speak>",
        TextType: "ssml",
        VoiceId: "Matthew",
        LanguageCode: "en-US",
      };
      if (user && !user.voiceType) {
        params.LanguageCode = "en-GB";
        params.VoiceId = "Amy";
      }

      const audio: any = await polly.synthesizeSpeech(params).promise();
      //console.log(audio);
      let uInt8Array = new Uint8Array(audio.AudioStream);
      let arrayBuffer = uInt8Array.buffer;
      audioContexts.forEach((item) => {
        item.close();
      });
      const audioCtx = new window.AudioContext();
      const source = audioCtx.createBufferSource();

      if (arrayBuffer) {
        // let audioBuffer1 = await audioCtx.decodeAudioData(arrayBuffer);
        // setAudioBuffer(audioBuffer1);
        let audioBuffer = await arrayBufferToAudioBuffer(arrayBuffer, audioCtx);

        source.loop = false;
        source.buffer = audioBuffer;
        source.connect(audioCtx.destination);
        source.start(0);
        setAudioContexts([audioCtx]);
      }
    } catch (error) {
      console.log(error);
    }

    // let blob = new Blob([arrayBuffer]);
    // let url = URL.createObjectURL(blob);

    // let elements = document.getElementsByClassName("bot-audio");
    // while (elements.length > 0) {
    //   elements[0].parentNode!.removeChild(elements[0]);
    // }

    // let audioElement: any = document.createElement("AUDIO");
    // audioElement.classList.add("bot-audio");
    // audioElement.controls = true;
    // document.body.appendChild(audioElement);

    // if (audioElement) {
    //   audioElement.src = url;
    //   audioElement.play();
    // }

    /*
         data = {
          AudioStream: <Binary String>, 
          ContentType: "audio/mpeg", 
          RequestCharacters: 37
         }
         */

    /*
    speechSynthesis.cancel();

    txt = txt.toString();
    let speech = new SpeechSynthesisUtterance();
    speech.lang = "en-US";
    speech.text = txt;
    speech.rate = speed;
    speech.volume = volume;
    speech.pitch = 1;

    speechSynthesis.speak(speech);
    */
  }

  useEffect(() => {
    if (awareCnt % 20 === 0) {
      setAwareRandom(Math.floor(Math.random() * 20));
    }
  }, [awareCnt]);

  useEffect(() => {
    if (associationCnt % 20 === 0) {
      setAssociationRandom(Math.floor(Math.random() * 20));
    }
  }, [associationCnt]);

  useEffect(() => {
    const func = async () => {
      if (chatHistory.length === 0) {
        let res = await canUseApp(method);
      }
    };

    if (user) {
      setProcessTipsShow(user.processTips);
      setProcessTimer(user.processTimer);
      if (user.goal) setGoal(user.goal);
      if (user.deadline) setDeadline(user.deadline);
    }

    func().catch(console.error);
  }, [user]);

  useEffect(() => {
    let m = searchParams.get("method");
    setMethod(m!);

    setNeedTimer(false);
    setIsResume(false);
    setChatHistory([]);

    const func = async (m: string) => {
      let res = await canUseApp(m!);
    };
    func(m!);
  }, [searchParams]);

  const addInstructions = async (m: string) => {
    let pTxt = {
      dp2: "DP2",
      satori1: "Satori 1",
      satori2: "Satori 2",
      satori3: "Satori 3",
      satori4: "Satori 4",
      "10f": "10F",
      ip_standard: "IP Standard",
      ip_advanced: "IP Advanced",
      universal: "Universal",
      ip_basic: "IP Basic",
      ip_simplified: "IP Simplified",
      satori_trainer: "Satori Trainer",
    };
    let botText = m === "" ? "" : pTxt[m as keyof typeof pTxt];



    if (user.introEnable) {
      switch (m) {
        case "universal":
          botText =
            "In this session, I’m going to guide you to release the emotional charge or stress you’re feeling around a particular problem. " +
            "\n\n" +
            "I’m going to guide you to place your attention on various parts of the problem. Your job is to report what comes up for you, in the form of thoughts, images, emotions or body sensations. " +
            "\n\n" +
            "With eyes closed, you’ll be feeling deeply into the situation you’re working with. Then concisely report your experience when prompted - without resisting, filtering, analyzing or changing what comes up. If nothing comes up, just report nothing. If the same things keep coming up, just report that." +
            "\n\n" +
            "I ask for your full commitment to see the process through to the end." +
            "\n\n" +
            "Are you ready to get started?";
          break;

        case "dp2":
          botText =
            "In this session, I’m going to guide you to release the emotional charge or stress you’re feeling around a particular problem." +
            "\n\n" +
            "I’m going to guide you to place your attention on various parts of the problem. Your job is to report what comes up for you, in the form of thoughts, images, emotions or body sensations." +
            "\n\n" +
            "With eyes closed, you’ll be feeling deeply into the situation you’re working with. Then concisely report your experience when prompted - without resisting, filtering, analyzing or changing what comes up. If nothing comes up, just report nothing. If the same things keep coming up, just report that." +
            "\n\n" +
            "I ask for your full commitment to see the process through to the end." +
            "\n\n" +
            "Are you ready to get started?";
          break;

        case "ip_simplified":
          botText =
            "In this session, I’m going to guide you to resolve a recurring problem or pattern in your life. " +
            "\n\n" +
            "I’m going to guide you to place your attention on various parts of the problem. Your job is to report what comes up for you, in the form of thoughts, images, emotions or body sensations." +
            "\n\n" +
            "With eyes closed, you’ll be feeling deeply into the situation you’re working with. Then concisely report your experience when prompted - without resisting, filtering, analyzing or changing what comes up." +
            "\n\n" +
            "If nothing comes up, just report nothing. If the same things keep coming up, just report that." +
            "\n\n" +
            "I ask for your full commitment to see the process through to the end." +
            "\n\n" +
            "Are you ready to get started?";
          break;

        case "ip_basic":
        botText =
          "In this session, I’m going to guide you to resolve a recurring problem or pattern in your life. " +
          "\n\n" +
          "I’m going to guide you to place your attention on various parts of the problem. Your job is to report what comes up for you, in the form of thoughts, images, emotions, body sensations and identities." +
          "\n\n" +
          "With eyes closed, you’ll be feeling deeply into the situation you’re working with. Then concisely report your experience when prompted - without resisting, filtering, analyzing or changing what comes up." +
          "\n\n" +
          "If nothing comes up, just report nothing. If the same things keep coming up, just report that." +
          "\n\n" +
          "I ask for your full commitment to see the process through to the end." +
          "\n\n" +
          "Are you ready to get started?";
        break;

        case "ip_standard":
          botText =
            "In this session, I’m going to guide you to resolve a recurring problem or pattern in your life." +
            "\n\n" +
            "I’m going to guide you to place your attention on various parts of the problem. Your job is to report what comes up for you, in the form of thoughts, images, emotions, body sensations and identities." +
            "\n\n" +
            "With eyes closed, you’ll be feeling deeply into the situation you’re working with. Then concisely report your experience when prompted - without resisting, filtering, analyzing or changing what comes up." +
            "\n\n" +
            "If nothing comes up, just report nothing. If the same things keep coming up, just report that." +
            "\n\n" +
            "I only ask for your full commitment to see the process through to the end." +
            "\n\n" +
            "Are you ready to get started?";
          break;

        case "ip_advanced":
          botText =
            "In this session, I’m going to guide you to resolve a recurring problem or pattern in your life." +
            "\n\n" +
            "I’m going to guide you to place your attention on various parts of the problem. Your job is to report what comes up for you, in the form of thoughts, images, emotions, body sensations and identities." +
            "\n\n" +
            "With eyes closed, you’ll be feeling deeply into the situation you’re working with. Then concisely report your experience when prompted - without resisting, filtering, analyzing or changing what comes up." +
            "\n\n" +
            "If nothing comes up, just report nothing. If the same things keep coming up, just report that." +
            "\n\n" +
            "I only ask for your full commitment to see the process through to the end." +
            "\n\n" +
            "Are you ready to get started?";
          break;
          
        case "satori1":
          botText =
            "In this session, I’m going to guide you to release concepts and reach a non-dual state of being." +
            "\n\n" +
            "I’m going to guide you to place your attention on a concept. Your job is to report what comes up for you, in the form of thoughts, images, emotions, body sensations and identities." +
            "\n\n" +
            "With eyes closed, you’ll be feeling deeply into the concept you’re working on. Then concisely report your experience when prompted - without resisting, filtering, analyzing or changing what comes up. Do not associate or use logic, just FEEL the concept and report your experience." +
            "\n\n" +
            "If nothing comes up, just report nothing. If the same things keep coming up, just report that." +
            "\n\n" +
            "Our goal is to reach the state of … “Just ME”... where you have no more mind contents or everyday identities." +
            "\n\n" +
            "I ask for your full commitment to see the process through to the end." +
            "\n\n" +
            "Are you ready to get started?";
          break;

        case "satori2":
        botText =
          "In this session, I’m going to guide you to integrate polarities and reach a non-dual state of being." +
          "\n\n" +
          "I’m going to guide you to place your attention on a concept. Your job is to report what comes up for you, in the form of thoughts, images, emotions, body sensations and identities." +
          "\n\n" +
          "With eyes closed, you’ll be feeling deeply into the concept you’re working on. Then concisely report your experience when prompted - without resisting, filtering, analyzing or changing what comes up. Do not associate or use logic, just FEEL the concept and report your experience." +
          "\n\n" +
          "If nothing comes up, just report nothing. If the same things keep coming up, just report that." +
          "\n\n" +
          "Our goal is to reach the state of … “Just ME”... where you have no more mind contents or everyday identities." +
          "\n\n" +
          "I ask for your full commitment to see the process through to the end." +
          "\n\n" +
          "Are you ready to get started?";
        break;
        
        case "satori3":
        botText =
          "In this session, I’m going to guide you to release emotions and reach a non-dual state of being." +
          "\n\n" +
          "I’m going to guide you to place your attention on an emotion triggered by a problem you’re experiencing. Your job is to report what comes up for you, in the form of thoughts, images, emotions, body sensations and identities." +
          "\n\n" +
          "With eyes closed, you’ll be feeling deeply into the emotion you’re working on. Then concisely report your experience when prompted - without resisting, filtering, analyzing or changing what comes up. Do not associate or use logic, just FEEL the concept and report your experience." +
          "\n\n" +
          "If nothing comes up, just report nothing. If the same things keep coming up, just report that." +
          "\n\n" +
          "Our goal is to reach the state of … “Just ME”... where you have no more mind contents or everyday identities." +
          "\n\n" +
          "I ask for your full commitment to see the process through to the end." +
          "\n\n" +
          "Are you ready to get started?";
        break;

        case "satori4":
        botText =
          "In this session, I’m going to guide you to integrate emotions and reach a non-dual state of being." +
          "\n\n" +
          "I’m going to guide you to place your attention on an emotion triggered by a problem you’re experiencing. Your job is to report what comes up for you, in the form of thoughts, images, emotions, body sensations and identities." +
          "\n\n" +
          "With eyes closed, you’ll be feeling deeply into the emotion you’re working on. Then concisely report your experience when prompted - without resisting, filtering, analyzing or changing what comes up. Do not associate or use logic, just FEEL the concept and report your experience." +
          "\n\n" +
          "If nothing comes up, just report nothing. If the same things keep coming up, just report that." +
          "\n\n" +
          "Our goal is to reach the state of … “Just ME”... where you have no more mind contents or everyday identities." +
          "\n\n" +
          "I ask for your full commitment to see the process through to the end." +
          "\n\n" +
          "Are you ready to get started?";
        break;

        case "aspect":
          botText =
            "In this process, you’ll be communicating with an aspect of yourself to clear a problem you’re experiencing. This aspect of yourself will communicate with you in the form of images, thoughts, emotions or body sensations." +
            "\n\n" +
            "Accept the first answer that comes up. You must feel fully and deeply into the situation you’re working with. It’s important you don’t resist anything, and accept whatever comes up during the process. Don’t filter, analyze or change what comes up." +
            "\n\n" +
            "You are to act as an impartial reporter of your experience, briefly answering the questions I have for you. Are you willing to do this fully and stay with the process until it's completion?";
          break;
        default:
          botText = "What trigger would you like to work on?";
          if (user && user.processTips) {
            botText +=
              "\n(Please describe a specific trigger using no more than 30 words.)";
          }
          break;
      }
      setStep(0);
    }
    else {
  
      switch (m) {
        case "satori_trainer":
          botText =
            "Welcome to the Satori Protocol Training. Have you completed the <a target='_blank' href='https://enlightenmenttherapy.co/sptraining'>training audios</a> yet?";
          break;
        case "satori1":
        case "satori2":
          botText = "What concept would you like to work on?";
          break;
        case "satori3":
        case "satori4":
          botText = "What trigger would you like to work on?";
          if (user && user.processTips)
            botText +=
              "\n(Please concisely describe the trigger in 1 sentence using 30 words max)";
          break;
        case "10f":
          if (
            user &&
            user.goal &&
            user.deadline &&
            user.goal !== "" &&
            user.deadline !== ""
          ) {
            botText =
              "Is your goal still " + user.goal + " by " + user.deadline + "?";
            if (user.processTips) botText += "\n(Please type 'yes' or 'no')";
            setStep(111);
          } else {
            setStep(1);
            botText = "What is your goal?";
          }
          break;
        case "aspect":
          botText = "What problem would you like to work on?";
          if (user && user.processTips) {
            botText +=
              "\n(Please describe your problem using no more than 10 words)";
          }
          setStep(2);
          break;
        default:
          botText = "What trigger would you like to work on?";
          if (user && user.processTips) {
            botText +=
              "\n(Please describe a specific trigger using no more than 30 words.)";
          }
          break;
      }
      if (m !== "10f" && m !== "aspect" ) setStep(1);
    }

    setChatHistory([
      {
        isBot: true,
        text: botText,
      },
    ]);
    
    setMeCnt(0);
    setSlowCnt(0);
    setPositiveCnt(0);
    setPositiveMeCnt(0);
    setAssociationCnt(0);
    setAwareCnt(0);
    setEmptyCnt(0);
    setEmptyCnt_N(0);
    setNeedTimer(false);
    setIsResume(false);

    setPrevQuestion(botText);
    setTipAdded(false);
    textToSpeech(botText);
    setFinished(0);
    setIsResume(false);

  };

  const canUseApp = async (m: string) => {
    console.log("canUseApp", user);
    if (user === null || m === "" || m == null) {
      setInputDisable(true);
      return -1;
    }

    if (user.role !== 0) {
      setInputDisable(false);
      setPurchaseModalShow(false);

      await addInstructions(m);
      return 1;
    }

    let isEnabled = false;

    if (user.billing.isFree && (m === "satori_trainer" || m === "satori1")) {
      isEnabled = true;
    }

    if (user.billing.isUniversal) {
      if (m.indexOf("universal") === 0 || m.indexOf("ip_simplified") === 0)
        isEnabled = true;
    }

    if (user.billing.isAspect && m.indexOf("aspect") === 0) {
      isEnabled = true;
    }

    if (user.billing.isAll) {
      isEnabled = true;
    }

    if (isEnabled == true) {
      setInputDisable(false);
      setPurchaseModalShow(false);
      console.log("m", m);
      await addInstructions(m);
    } else {
      setPurchaseModalShow(true);
      setChatHistory([
        {
          isBot: true,
          text: "Sorry, you don’t have access to this process yet. <a target='_blank' href='https://enlightenmenttherapy.co/etappfull'>Click here</a> to upgrade to full access.",
        },
      ]);
    }
  };

  const onSave = () => {
    API.saveChatLog({ text: chatHistory, method: method });
  };

  const purchaseModalClose = () => {
    setPurchaseModalShow(false);
  };
  const forceUpdate = useForceUpdate();
  const timeout = (delay: number) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const scrollToBottom = () => {
    if (scrollBarObj) scrollBarObj.scrollToBottom();
  };

  const isBugReport = (text: string) => {
    let str: string = text.toLowerCase();
    if (
      str.indexOf("confused - ") === 0 ||
      str.indexOf("bug - ") === 0 ||
      str.indexOf("log - ") === 0
    )
      return 1;
    return 0;
  };

  const isRepeat = (text: string) => {
    let str: string = text.toLowerCase();
    if (str === "repeat") return 1;
    return 0;
  };

  const inputChange = async (input: string) => {
    //console.log("a");
    if (timerObj !== null) {
      //console.log("B");
      clearTimeout(timerObj);
      //const timeout = setTimeout(timerFunc, responseTime);
      //setTimerObj(timeout);
    }
  };

  const handleSatori1 = (input: string) => {
    let cnt = 0;
    let save = 0;
    let eCnt = 0;
    let botText = "";

    switch (step) {
      case 0:
        botText = "What concept would you like to work on?";
        setStep(1);
        break;
      case 1:
        if ((input.match(/ /g) || []).length > 1) {
          botText =
            "Too many words. Let’s try again. What concept would you like to work on?";
          if (processTipsShow) {
            botText += "\n(Describe in no more than 2 words)";
          }
        } else {
          setTopic(input);
          botText =
            "Feel what '" + input + "' is, and tell me something about it.";
          setStep(2);
          setNeedTimer(true);
        }
        break;
      case 2:
        if (checkSame(input)) {
          botText =
            "Please avoid using '" +
            input +
            "'. Let’s try again. Feel what '" +
            topic +
            "' is, and tell me something about it.";
        } else {
          eCnt = 0;
          if (checkEmptiness(input)) {
            eCnt = emptyCnt + 1;
          } else {
            eCnt = 0;
          }

          setEmptyCnt(eCnt);

          if (checkMe(input)) cnt = meCnt + 1;
          else cnt = 0;

          if (cnt === 2) {
            let t1 = meInput.toLowerCase();
            let t2 = input.toLowerCase();
            if (t1 === t2 || "also " + t1 === t2) {
              botText = "Is it the same '" + meInput + "' on both sides?";
            } else if (t1 === "also " + t2) {
              botText = "Is it the same '" + input + "' on both sides?";
            } else {
              botText = "Is '" + meInput + "' the same as '" + input + "'?";
            }
            setStep(4);
            setMeCnt(0);
          } else {
            setMeInput(input);
            setMeCnt(cnt);
            if (eCnt === 3) {
              botText = "Who is aware of... '" + input + "'?";
              setStep(444);
              setEmptyCnt(0);
            } else {
              botText =
                "Feel what '" +
                topic +
                "' is not, and tell me something about it.";
              setStep(3);
            }
          }
        }
        break;
      case 444:
        botText =
          "Be aware of yourself, not just your thoughts and emotions. Now feel what '" +
          topic +
          "' is not, and tell me something about it.";
        setMeCnt(0);
        setStep(3);
        break;
      case 555:
        botText =
          "Be aware of yourself, not just your thoughts and emotions. Now feel what '" +
          topic +
          "' is, and tell me something about it.";
        setMeCnt(0);
        setStep(2);
        break;
      case 3:
        if (checkSame(input)) {
          botText =
            "Please avoid using '" +
            input +
            "'. Let’s try again. Feel what '" +
            topic +
            "' is not, and tell me something about it.";
        } else {
          eCnt = 0;

          if (checkEmptiness(input)) {
            eCnt = emptyCnt_N + 1;
          } else {
            eCnt = 0;
          }
          setEmptyCnt_N(eCnt);

          if (checkMe(input)) cnt = meCnt + 1;
          else cnt = 0;

          if (cnt === 2) {
            let t1 = meInput.toLowerCase();
            let t2 = input.toLowerCase();
            if (t1 === t2 || "also " + t1 === t2) {
              botText = "Is it the same '" + meInput + "' on both sides?";
            } else if (t1 === "also " + t2) {
              botText = "Is it the same '" + input + "' on both sides?";
            } else {
              botText = "Is '" + meInput + "' the same as '" + input + "'?";
            }
            setStep(4);
            setMeCnt(0);
          } else {
            setMeInput(input);
            setMeCnt(cnt);
            if (eCnt === 3) {
              botText = "Who is aware of... '" + input + "'?";
              setStep(555);
              setEmptyCnt_N(0);
            } else {
              botText =
                "Feel what '" + topic + "' is, and tell me something about it.";
              setStep(2);
            }
          }
        }
        break;
      case 4:
        if (checkYes(input)) {
          botText = "Great! We are done";
          save = 1;
        } else if (checkNo(input)) {
          botText =
            "Feel what '" + topic + "' is, and tell me something about it.";
          setStep(2);
        }
        break;
    }

    return { botText, save };
  };

  const handleSatori2 = (input: string) => {
    let cnt = 0;
    let save = 0;
    let eCnt = 0;
    let botText = "";

    switch (step) {
      case 0:
        botText = "What concept would you like to work on?";
        setStep(1);
        break;
      case 1:
        if ((input.match(/ /g) || []).length > 1) {
          botText =
            "Too many words. Let’s try again. What concept would you like to work on?";
          if (processTipsShow) {
            botText += "\n(Describe in no more than 2 words)";
          }
        } else {
          setConcept(input);
          botText = "And what is the opposite of '" + input + "'?";
          setStep(2);
        }
        break;
      case 2:
        if ((input.match(/ /g) || []).length > 1) {
          botText =
            "Too many words. Let’s try again. And what is the opposite of '" +
            concept +
            "'?";
          if (processTipsShow) {
            botText += "\n(Describe in no more than 2 words)";
          }
        } else {
          setConceptOpp(input);
          botText =
            "Feel what '" + concept + "' is, and tell me something about it.";
          setStep(3);
          setNeedTimer(true);
        }
        break;
      case 3:
        if (checkSame(input)) {
          botText =
            "Please avoid using '" +
            input +
            "'. Let’s try again. Feel what '" +
            concept +
            "' is, and tell me something about it.";
        } else {
          eCnt = 0;

          if (checkEmptiness(input)) {
            eCnt = emptyCnt + 1;
          } else {
            eCnt = 0;
          }

          setEmptyCnt(eCnt);

          if (checkMe(input)) cnt = meCnt + 1;
          else cnt = 0;

          if (cnt === 2) {
            let t1 = meInput.toLowerCase();
            let t2 = input.toLowerCase();
            if (t1 === t2 || "also " + t1 === t2) {
              botText = "Is it the same '" + meInput + "' on both sides?";
            } else if (t1 === "also " + t2) {
              botText = "Is it the same '" + input + "' on both sides?";
            } else {
              botText = "Is '" + meInput + "' the same as '" + input + "'?";
            }
            setStep(5);
            setMeCnt(0);
          } else {
            setMeInput(input);
            setMeCnt(cnt);
            if (eCnt === 3) {
              botText = "Who is aware of... '" + input + "'?";
              setStep(444);
              setEmptyCnt(0);
            } else {
              botText =
                "Feel what '" +
                conceptOpp +
                "' is, and tell me something about it.";
              setStep(4);
            }
          }
        }

        break;
      case 444:
        botText =
          "Be aware of yourself, not just your thoughts and emotions. Now feel what '" +
          conceptOpp +
          "' is, and tell me something about it.";
        setMeCnt(0);
        setStep(4);
        break;
      case 555:
        botText =
          "Be aware of yourself, not just your thoughts and emotions. Now feel what '" +
          concept +
          "' is, and tell me something about it.";
        setMeCnt(0);
        setStep(3);
        break;
      case 4:
        if (checkSame(input)) {
          botText =
            "Please avoid using '" +
            input +
            "'. Let’s try again. Feel what '" +
            conceptOpp +
            "' is, and tell me something about it.";
        } else {
          eCnt = 0;

          if (checkEmptiness(input)) {
            eCnt = emptyCnt_N + 1;
          } else {
            eCnt = 0;
          }
          setEmptyCnt_N(eCnt);

          if (checkMe(input)) cnt = meCnt + 1;
          else cnt = 0;

          if (cnt === 2) {
            let t1 = meInput.toLowerCase();
            let t2 = input.toLowerCase();
            if (t1 === t2 || "also " + t1 === t2) {
              botText = "Is it the same '" + meInput + "' on both sides?";
            } else if (t1 === "also " + t2) {
              botText = "Is it the same '" + input + "' on both sides?";
            } else {
              botText = "Is '" + meInput + "' the same as '" + input + "'?";
            }
            setStep(5);
            setMeCnt(0);
          } else {
            setMeInput(input);
            setMeCnt(cnt);
            if (eCnt === 3) {
              botText = "Who is aware of... '" + input + "'?";
              setStep(555);
              setEmptyCnt_N(0);
            } else {
              botText =
                "Feel what '" +
                concept +
                "' is, and tell me something about it.";
              setStep(3);
            }
          }
        }

        break;
      case 5:
        if (checkYes(input)) {
          botText = "Great! We are done";
          save = 1;
        } else if (checkNo(input)) {
          botText =
            "Feel what '" + concept + "' is, and tell me something about it.";
          setStep(3);
        }
        break;
    }
    return { botText, save };
  };
  const handleSatori3 = (input: string) => {
    let cnt = 0;
    let save = 0;
    let eCnt = 0;
    let botText = "";

    switch (step) {
      case 0:
        botText = "What trigger would you like to work on?";
        if (processTipsShow)
          botText +=
            "\n(Please concisely describe the trigger in 1 sentence using 30 words max)";
        setStep(1);
        break;
      case 1:
        if ((input.match(/ /g) || []).length > 29) {
          botText =
            "Too many words. Please describe your topic concisely using 30 words or less.";
        } else {
          setTopic(input);
          botText =
            "When you think about '" + input + "', what emotion do you feel?";
          if (processTipsShow) {
            botText += "\n(Describe the emotion using 1 word only)";
          }
          setStep(2);
        }
        break;
      case 2:
        if (input.indexOf(" ") !== -1) {
          botText =
            "Please describe your emotion using 1 word only. For instance: sadness, frustration, fear, worry, and so on. Let’s try again. When you think about '" +
            topic +
            "', what emotion do you feel? ";
        } else {
          setEmotion(input);
          botText =
            "Feel what '" + input + "' is, and tell me something about it.";
          setStep(3);
          setNeedTimer(true);
        }
        break;
      case 3:
        if (checkSame(input)) {
          botText =
            "Please avoid using '" +
            input +
            "'. Let’s try again. Feel what '" +
            emotion +
            "' is, and tell me something about it.";
        } else {
          eCnt = 0;

          if (checkEmptiness(input)) {
            eCnt = emptyCnt + 1;
          } else {
            eCnt = 0;
          }
          setEmptyCnt(eCnt);

          if (checkMe(input)) cnt = meCnt + 1;
          else cnt = 0;

          if (cnt === 2) {
            let t1 = meInput.toLowerCase();
            let t2 = input.toLowerCase();
            if (t1 === t2 || "also " + t1 === t2) {
              botText = "Is it the same '" + meInput + "' on both sides?";
            } else if (t1 === "also " + t2) {
              botText = "Is it the same '" + input + "' on both sides?";
            } else {
              botText = "Is '" + meInput + "' the same as '" + input + "'?";
            }
            setStep(5);
            setMeCnt(0);
          } else {
            setMeInput(input);
            setMeCnt(cnt);
            if (eCnt === 3) {
              botText = "Who is aware of... '" + input + "'?";
              setStep(444);
              setEmptyCnt(0);
            } else {
              botText =
                "Feel what '" +
                emotion +
                "' is not, and tell me something about it.";
              setStep(4);
            }
          }
        }
        break;
      case 444:
        botText =
          "Be aware of yourself, not just your thoughts and emotions. Now feel what '" +
          emotion +
          "' is not, and tell me something about it.";
        setStep(4);
        setMeCnt(0);
        break;
      case 555:
        botText =
          "Be aware of yourself, not just your thoughts and emotions. Now feel what '" +
          emotion +
          "' is, and tell me something about it.";
        setMeCnt(0);
        setStep(3);
        break;
      case 4:
        if (checkSame(input)) {
          botText =
            "Please avoid using '" +
            input +
            "'. Let’s try again. Feel what '" +
            emotion +
            "' is not, and tell me something about it.";
        } else {
          eCnt = 0;

          if (checkEmptiness(input)) {
            eCnt = emptyCnt_N + 1;
          } else {
            eCnt = 0;
          }
          setEmptyCnt_N(eCnt);

          if (checkMe(input)) cnt = meCnt + 1;
          else cnt = 0;

          if (cnt === 2) {
            let t1 = meInput.toLowerCase();
            let t2 = input.toLowerCase();
            if (t1 === t2 || "also " + t1 === t2) {
              botText = "Is it the same '" + meInput + "' on both sides?";
            } else if (t1 === "also " + t2) {
              botText = "Is it the same '" + input + "' on both sides?";
            } else {
              botText = "Is '" + meInput + "' the same as '" + input + "'?";
            }
            setStep(5);
            setMeCnt(0);
          } else {
            setMeInput(input);
            setMeCnt(cnt);
            if (eCnt === 3) {
              botText = "Who is aware of... '" + input + "'?";
              setStep(555);
              setEmptyCnt_N(0);
            } else {
              botText =
                "Feel what '" +
                emotion +
                "' is, and tell me something about it.";
              setStep(3);
            }
          }
        }
        break;
      case 5:
        if (checkYes(input)) {
          setNeedTimer(false);
          botText =
            "Now let’s check in. When you think about '" +
            topic +
            "', do you feel neutral, positive or negative?";
          if (processTipsShow) {
            botText += "\n(Please type 'negative', 'neutral', or 'positive')";
          }
          setStep(6);
        } else if (checkNo(input)) {
          botText =
            "Feel what '" + emotion + "' is, and tell me something about it.";
          setStep(3);
        }
        break;
      case 6:
        let t = checkNegative(input);

        if (t === -1) {
          // botText = prevQuestion;
        } else {
          if (t === 0) {
            botText = "Is there anything else bad about '" + topic + "'?";
            setStep(7);
          } else {
            setEmotion(input);
            botText = "Would you like to start a new process?";
            setStep(8);
          }
          if (processTipsShow) {
            botText += "\n(Please type 'yes' or 'no')";
          }
        }
        break;
      case 7:
        if (checkYes(input)) {
          botText = "Would you like to start a new process?";
          if (processTipsShow) {
            botText += "\n(Please type 'yes' or 'no')";
          }
          setStep(9);
        } else if (checkNo(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Great! We are done";
            save = 1;
          }
        }
        break;
      case 8:
        if (checkYes(input)) {
          botText =
            "When you think about '" +
            topic +
            "' what negative emotion do you feel?";
          setStep(2);
        } else if (checkNo(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Okay! We are done for now";
            save = 1;
          }
        }
        break;
      case 9:
        if (checkYes(input)) {
          botText =
            "Okay, please describe what else is bad about '" +
            topic +
            "' in one sentence.";
          setStep(1);
        } else if (checkNo(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Okay! We are done for now";
            save = 1;
          }
        }
        break;
      case 2221:
        botText = integrationQues[1].replaceAll("[TRIGGER]", topic);
        setStep(2222);
        break;
      case 2222:
        botText = integrationQues[2].replaceAll("[TRIGGER]", topic);
        setStep(2223);
        break;
      case 2223:
        botText = integrationQues[3].replaceAll("[TRIGGER]", topic);
        setStep(2224);
        break;
      case 2224:
        botText = integrationQues[4].replaceAll("[TRIGGER]", topic);
        setStep(2225);
        break;
      case 2225:
        botText = "Great! We are done";
        save = 1;
        break;
    }

    return { botText, save };
  };

  const handleSatori4 = (input: string) => {
    let cnt = 0;
    let save = 0;
    let eCnt = 0;
    let botText = "";

    switch (step) {
      case 0:
        botText = "What trigger would you like to work on?";
        if (processTipsShow)
          botText +=
            "\n(Please concisely describe the trigger in 1 sentence using 30 words max)";
        setStep(1);
        break;
      case 1:
        if ((input.match(/ /g) || []).length > 29) {
          botText =
            "Too many words. Please describe your topic concisely using 30 words or less.";
        } else {
          setTopic(input);
          botText =
            "When you think about '" + input + "', what emotion do you feel?";
          if (processTipsShow) {
            botText += "\n(Describe the emotion using 1 word only)";
          }
          setStep(2);
        }
        break;
      case 2:
        if (input.indexOf(" ") !== -1) {
          botText =
            "Please describe your emotion using 1 word only. For instance: sadness, frustration, fear, worry, and so on. Let’s try again. When you think about '" +
            topic +
            "', what emotion do you feel? ";
        } else {
          setEmotion(input);
          botText =
            "Instead of '" + input + "', what emotion would you like to feel?";
          setStep(3);
        }
        break;
      case 3:
        if (input.indexOf(" ") !== -1) {
          botText =
            "Please describe your emotion using 1 word only. For instance: sadness, frustration, fear, worry, and so on. Let’s try again. When you think about '" +
            topic +
            "', what emotion do you feel? ";
        } else {
          setEmotion2(input);
          botText =
            "Feel what '" + emotion + "' is, and tell me something about it.";
          setStep(4);
          setNeedTimer(true);
        }
        break;
      case 4:
        if (checkSame(input)) {
          botText =
            "Please avoid using '" +
            input +
            "'. Let’s try again. Feel what '" +
            emotion +
            "' is, and tell me something about it.";
        } else {
          eCnt = 0;

          if (checkEmptiness(input)) {
            eCnt = emptyCnt + 1;
          } else {
            eCnt = 0;
          }
          setEmptyCnt(eCnt);

          if (checkMe(input)) cnt = meCnt + 1;
          else cnt = 0;

          if (cnt === 2) {
            let t1 = meInput.toLowerCase();
            let t2 = input.toLowerCase();
            if (t1 === t2 || "also " + t1 === t2) {
              botText = "Is it the same '" + meInput + "' on both sides?";
            } else if (t1 === "also " + t2) {
              botText = "Is it the same '" + input + "' on both sides?";
            } else {
              botText = "Is '" + meInput + "' the same as '" + input + "'?";
            }
            setStep(6);
            setMeCnt(0);
          } else {
            setMeInput(input);
            setMeCnt(cnt);
            if (eCnt === 3) {
              botText = "Who is aware of... '" + input + "'?";
              setStep(444);
              setEmptyCnt(0);
            } else {
              botText =
                "Feel what '" +
                emotion2 +
                "' is, and tell me something about it.";
              setStep(5);
            }
          }
        }
        break;
      case 444:
        botText =
          "Be aware of yourself, not just your thoughts and emotions. Now feel what '" +
          emotion2 +
          "' is, and tell me something about it.";
        setMeCnt(0);
        setStep(5);
        break;
      case 555:
        botText =
          "Be aware of yourself, not just your thoughts and emotions. Now feel what '" +
          emotion +
          "' is, and tell me something about it.";
        setMeCnt(0);
        setStep(4);
        break;
      case 5:
        if (checkSame(input)) {
          botText =
            "Please avoid using '" +
            input +
            "'. Let’s try again. Feel what '" +
            emotion2 +
            "' is, and tell me something about it.";
        } else {
          eCnt = 0;

          if (checkEmptiness(input)) {
            eCnt = emptyCnt_N + 1;
          } else {
            eCnt = 0;
          }
          setEmptyCnt_N(eCnt);

          if (checkMe(input)) cnt = meCnt + 1;
          else cnt = 0;

          if (cnt === 2) {
            let t1 = meInput.toLowerCase();
            let t2 = input.toLowerCase();
            if (t1 === t2 || "also " + t1 === t2) {
              botText = "Is it the same '" + meInput + "' on both sides?";
            } else if (t1 === "also " + t2) {
              botText = "Is it the same '" + input + "' on both sides?";
            } else {
              botText = "Is '" + meInput + "' the same as '" + input + "'?";
            }
            setStep(6);
            setMeCnt(0);
          } else {
            setMeInput(input);
            setMeCnt(cnt);
            if (eCnt === 3) {
              botText = "Who is aware of... '" + input + "'?";
              setStep(555);
              setEmptyCnt_N(0);
            } else {
              botText =
                "Feel what '" +
                emotion +
                "' is, and tell me something about it.";
              setStep(4);
            }
          }
        }
        break;
      case 6:
        if (checkYes(input)) {
          setNeedTimer(false);
          botText =
            "Now When you think about '" +
            topic +
            "', do you feel neutral, positive or negative?";
          if (processTipsShow) {
            botText += "\n(Please type 'negative', 'neutral', or 'positive')";
          }
          setStep(7);
        } else if (checkNo(input)) {
          botText =
            "Feel what '" + emotion + "' is, and tell me something about it.";
          setStep(4);
        }
        break;
      case 7:
        let t = checkNegative(input);

        if (t === -1) {
          // botText = prevQuestion;
        } else {
          if (t === 0) {
            botText = "Is there anything else bad about '" + topic + "'?";
            setStep(8);
          } else {
            setEmotion(input);
            botText = "Would you like to start a new process?";
            setStep(9);
          }
          if (processTipsShow) {
            botText += "\n(Please type 'yes' or 'no')";
          }
        }
        break;
      case 8:
        if (checkYes(input)) {
          botText = "Would you like to start a new process?";
          if (processTipsShow) {
            botText += "\n(Please type 'yes' or 'no')";
          }
          setStep(10);
        } else if (checkNo(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Great! We are done";
            save = 1;
          }
        }
        break;
      case 9:
        if (checkYes(input)) {
          botText =
            "When you think about '" +
            topic +
            "' what negative emotion do you feel?";
          setStep(2);
        } else if (checkNo(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Okay! We are done for now";
            save = 1;
          }
        }
        break;
      case 10:
        if (checkYes(input)) {
          botText =
            "Okay, please describe what else is bad about '" +
            topic +
            "' in one sentence.";
          setStep(1);
        } else if (checkNo(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Okay! We are done for now";
            save = 1;
          }
        }
        break;

      case 2221:
        botText = integrationQues[1].replaceAll("[TRIGGER]", topic);
        setStep(2222);
        break;
      case 2222:
        botText = integrationQues[2].replaceAll("[TRIGGER]", topic);
        setStep(2223);
        break;
      case 2223:
        botText = integrationQues[3].replaceAll("[TRIGGER]", topic);
        setStep(2224);
        break;
      case 2224:
        botText = integrationQues[4].replaceAll("[TRIGGER]", topic);
        setStep(2225);
        break;
      case 2225:
        botText = "Great! We are done";
        save = 1;
        break;
    }

    return { botText, save };
  };

  const handleSatoriTrainer = (input: string) => {
    let cnt = 0;
    let save = 0;
    let eCnt = 0;
    let botText = "";

    switch (step) {
      case 0:
        botText =
          "Welcome to the Satori Protocol Training. Have you completed the <a target='_blank' href='https://enlightenmenttherapy.co/sptraining'>training audios</a> yet?";
        setStep(1);
        break;
      case 1:
        if (checkYes(input)) {
          botText =
            "Great. In this training protocol, you’ll be instructed to feel who you are and who you are not. Be sure to FEEL your answer and type your response. Pay attention to both content as well as identities that come up. The goal is to reach “me” on both sides. Type ‘start’ when you’re ready to begin.";
          setStep(3);
        } else if (checkNo(input)) {
          botText =
            "Please listen to the audios before starting this training.";
        }
        break;
      case 3:
        if (checkStart(input)) {
          botText = "Feel who you are, and tell me something about it.";
          setStep(4);
          setNeedTimer(true);
        }
        break;
      case 4:
        if (checkSame(input)) {
          botText =
            "Please avoid using '" +
            input +
            "'. Let’s try again. Feel who you are, and tell me something about it.";
        } else {
          let pMeCnt = 0;
          let eCnt = 0;

          if (checkEmptiness(input)) {
            eCnt = emptyCnt + 1;
          } else {
            eCnt = 0;
          }

          if (checkMe(input)) {
            cnt = meCnt + 1;
            pMeCnt = positiveMeCnt + 1;
          } else {
            cnt = 0;
            pMeCnt = 0;
          }

          setEmptyCnt(eCnt);

          if (cnt === 2) {
            let t1 = meInput.toLowerCase();
            let t2 = input.toLowerCase();
            if (t1 === t2 || "also " + t1 === t2) {
              botText = "Is it the same '" + meInput + "' on both sides?";
            } else if (t1 === "also " + t2) {
              botText = "Is it the same '" + input + "' on both sides?";
            } else {
              botText = "Is '" + meInput + "' the same as '" + input + "'?";
            }
            setStep(6);
            setMeCnt(0);
          } else {
            setMeInput(input);
            setMeCnt(cnt);
            setPositiveMeCnt(pMeCnt);
            if (eCnt === 3) {
              botText = "Who is aware of... '" + input + "'?";
              setStep(444);
              setEmptyCnt(0);
            } else if (pMeCnt === 3) {
              botText =
                "Think... 'Who am I not?'...<break time='3s'/> who thinks that?";
              setPositiveMeCnt(0);
              setStep(5);
            } else {
              if (awareCnt % 20 === awareRandom) {
                if (cnt == 0) {
                  botText = "Who is aware of... '" + input + "'?";
                  setStep(44);
                } else {
                  setAwareCnt(awareCnt - 1);
                }
              } else {
                if (associationCnt % 20 === associationRandom) {
                  botText =
                    "Think... ‘who am I not?’...<break time='3s'/> what do you feel?";
                  setMeCnt(0);
                } else
                  botText =
                    "Feel who you are not, and tell me something about it.";
                setStep(5);
                setAssociationCnt(associationCnt + 1);
              }
            }
          }
        }
        break;
      case 44:
        if (associationCnt % 20 === associationRandom) {
          botText =
            "Think... ‘who am I not?’...<break time='3s'/> what do you feel?";
          setMeCnt(0);
        } else
          botText = "Feel who you are not, and tell me something about it.";
        setStep(5);
        setAssociationCnt(associationCnt + 1);
        break;
      case 444:
        botText =
          "Be aware of yourself, not just your thoughts and emotions. Now feel who you are not and tell me something about it.";
        setMeCnt(0);
        setStep(5);
        break;
      case 555:
        botText =
          "Be aware of yourself, not just your thoughts and emotions. Now feel who you are and tell me something about it.";
        setMeCnt(0);
        setStep(4);
        break;
      case 5:
        if (checkSame(input)) {
          botText =
            "Please avoid using '" +
            input +
            "'. Let’s try again. Feel who you are not, and tell me something about it.";
        } else {
          let eCnt = 0;

          if (checkEmptiness(input)) {
            eCnt = emptyCnt_N + 1;
          } else {
            eCnt = 0;
          }
          setEmptyCnt_N(eCnt);

          if (checkMe(input)) cnt = meCnt + 1;
          else cnt = 0;

          if (cnt === 2) {
            let t1 = meInput.toLowerCase();
            let t2 = input.toLowerCase();
            if (t1 === t2 || "also " + t1 === t2) {
              botText = "Is it the same '" + meInput + "' on both sides?";
            } else if (t1 === "also " + t2) {
              botText = "Is it the same '" + input + "' on both sides?";
            } else {
              botText = "Is '" + meInput + "' the same as '" + input + "'?";
            }
            setStep(6);
            setMeCnt(0);
          } else {
            setMeInput(input);
            setMeCnt(cnt);
            if (eCnt === 3) {
              botText = "Who is aware of... '" + input + "'?";
              setStep(555);
              setEmptyCnt_N(0);
            } else {
              if (associationCnt % 20 === associationRandom) {
                botText =
                  "Think... ‘who am I?’...<break time='3s'/> what do you feel?";
                setMeCnt(0);
              } else
                botText = "Feel who you are, and tell me something about it.";
              setStep(4);
              setAwareCnt(awareCnt + 1);
            }
          }
        }
        break;
      case 6:
        if (checkYes(input)) {
          botText =
            "Great! We are done. Now you may use the other Satori Protocol methods. We recommend you complete this training 2 more times to familiarize yourself with the Satori protocol.";
          save = 1;
        } else if (checkNo(input)) {
          botText = "Feel who you are, and tell me something about it.";
          setStep(4);
        }
        break;
    }
    return { botText, save };
  };

  const handleIpStandard = async (input: string) => {
    let cnt = 0;
    let save = 0;
    let eCnt = 0;
    let botText = "";
    switch (step) {
      case 0:
        botText = "What trigger would you like to work on?";
        if (processTipsShow) {
          botText +=
            "\n(Please describe a specific trigger using no more than 30 words.)";
        }
        setStep(1);
        break;
      case 1:
        setTopic(input);
        botText =
          "When you think about '" + input + "', what emotion do you feel?";
        if (processTipsShow) {
          botText += "\n(Describe the emotion using 1 word only)";
        }
        setStep(2);
        break;
      case 2:
        if (input.indexOf(" ") !== -1) {
          botText =
            "Please describe your emotion using 1 word only. For instance: sadness, frustration, fear, worry, and so on. Let’s try again. When you think about '" +
            topic +
            "', what emotion do you feel? ";
        } else {
          let tmp = input;
          if (checkYes(input)) tmp = emotion;
          else setEmotion(input);

          botText =
            "Now feel '" +
            tmp +
            "' and tell me, what is something bad about it?";
          setStep(3);
        }
        break;
      case 3:
        if (checkNothing(input)) {
          botText =
            "Now feel '" +
            emotion +
            "' and tell me, what is something good about it?";
          setStep(4);
          setTipAdded(false);
        } else {
          botText =
            "Feel '" +
            emotion +
            "' and tell me, is there something else bad about it?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 4:
        if (checkNothing(input)) {
          botText =
            "Now shift your attention from '" +
            emotion +
            "' to yourself. Feel yourself… feel your identity… what identity is that? What kind of person are you?";
          setStep(5);
          setTipAdded(false);
        } else {
          botText =
            "Feel '" +
            emotion +
            "' and tell me, is there something else good about it?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 5:
        setIdentity(input);
        botText =
          "Now take on the identity of '" +
          input +
          "'. And from the point of view of '" +
          input +
          "', what need or intention do you have?";
        setStep(6);
        break;
      case 6:
        if (checkNothing(input)) {
          botText =
            "And from the point of view of '" +
            identity +
            "', what belief or conclusion do you have?";
          setStep(7);
          setTipAdded(false);
        } else {
          botText =
            "From the point of view of '" +
            identity +
            "', do you have any other needs or intentions?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 7:
        if (checkNothing(input)) {
          botText =
            "Now instead of '" +
            identity +
            "', what kind of person would you like to be instead?";
          setStep(8);
          setTipAdded(false);
        } else {
          botText =
            "From the point of view of '" +
            identity +
            "', do you have any other beliefs or conclusions?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 8:
        setIdentity2(input);
        botText =
          "Now take on the identity of '" +
          input +
          "'. And from the point of view of '" +
          input +
          "', what need or intention do you have?";
        setStep(9);
        break;
      case 9:
        if (checkNothing(input)) {
          botText =
            "And from the point of view of '" +
            identity2 +
            "', what belief or conclusion do you have?";
          setStep(10);
          setTipAdded(false);
        } else {
          botText =
            "From the point of view of '" +
            identity2 +
            "', do you have any other needs or intentions?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 10:
        if (checkNothing(input)) {
          botText =
            "Now feel both identities at the same time… '" +
            identity +
            "' and '" +
            identity2 +
            "'. And as you feel them at the same time, take a deep inhale and exhale.";

          if (processTipsShow) botText += "\n(Type 'done' when ready)";

          setStep(11);
          setTipAdded(false);
        } else {
          botText =
            "From the point of view of '" +
            identity2 +
            "', do you have any other beliefs or conclusions?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 11:
        if (checkDone(input)) {
          botText =
            "Now can you feel the two separately… '" +
            identity +
            "' and '" +
            identity2 +
            "'... or no?";

          if (processTipsShow) {
            botText += "\n(Please type 'yes' or 'no')";
          }

          setStep(12);
        } else {
          botText = "";
        }
        break;
      case 12:
        if (checkYes(input)) {
          botText =
            "Now if '" +
            identity +
            "' and '" +
            identity2 +
            "' became one, what’s something bad that could happen as a result?";
          setStep(13);
        } else if (checkNo(input)) {
          botText =
            "Now let’s check in. When you think about '" +
            topic +
            "', what emotion do you feel now?";
          setStep(17);
        } else {
          botText =
            "Now can you feel the two separately… '" +
            identity +
            "' and '" +
            identity2 +
            "'... or no?";

          if (processTipsShow) {
            botText += "\n(Please type 'yes' or 'no')";
          }
        }
        break;
      case 13:
        if (checkNothing(input)) {
          botText =
            "Now if '" +
            identity +
            "' and '" +
            identity2 +
            "' became one, what’s something good that could happen as a result?";
          setStep(14);
          setTipAdded(false);
        } else {
          botText =
            "Now if '" +
            identity +
            "' and '" +
            identity2 +
            "' became one, could something else bad happen?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 14:
        if (checkNothing(input)) {
          botText =
            "Now feel both identities at the same time…  '" +
            identity +
            "' and '" +
            identity2 +
            "'. And as you feel them at the same time, take a deep inhale and exhale.";

          if (processTipsShow) botText += "\n(Type 'done' when ready)";

          setStep(15);
          setTipAdded(false);
        } else {
          botText =
            "Now if '" +
            identity +
            "' and '" +
            identity2 +
            "' became one, could something else good happen?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 15:
        if (checkDone(input)) {
          botText =
            "Now can you feel the two separately… '" +
            identity +
            "' and '" +
            identity2 +
            "'... or no?";

          if (processTipsShow) {
            botText += "\n(Please type 'yes' or 'no')";
          }
          setStep(16);
        } else {
          botText = "";
        }
        break;
      case 16:
        if (checkYes(input)) {
          botText =
            "Is '" +
            identity +
            "' still the best name for that identity, or can you give me a better one?";
          setStep(5);
        } else if (checkNo(input)) {
          botText =
            "Now let’s check in. When you think about '" +
            topic +
            "', what emotion do you feel now?";
          setStep(17);
        } else {
          botText =
            "Now can you feel the two separately… '" +
            identity +
            "' and '" +
            identity2 +
            "'... or no?";

          if (processTipsShow) {
            botText += "\n(Please type 'yes' or 'no')";
          }
        }
        break;
      case 17:
        if (await checkPositiveEmotion(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Great! We are done";
            save = 1;
          }
        } else {
          setEmotion(input);
          botText =
            "Now feel '" +
            input +
            "' and tell me, what is something bad about it?";
          setStep(3);
        }
        break;
      case 2221:
        botText = integrationQues[1].replaceAll("[TRIGGER]", topic);
        setStep(2222);
        break;
      case 2222:
        botText = integrationQues[2].replaceAll("[TRIGGER]", topic);
        setStep(2223);
        break;
      case 2223:
        botText = integrationQues[3].replaceAll("[TRIGGER]", topic);
        setStep(2224);
        break;
      case 2224:
        botText = integrationQues[4].replaceAll("[TRIGGER]", topic);
        setStep(2225);
        break;
      case 2225:
        botText = "Great! We are done";
        save = 1;
        break;
    }
    console.log(botText);
    return { botText, save };
  };

  const handleIpAdvanced = async (input: string) => {
    let cnt = 0;
    let save = 0;
    let eCnt = 0;
    let botText = "";

    switch (step) {
      case 0:
        botText = "What trigger would you like to work on?";
        if (processTipsShow) {
          botText +=
            "\n(Please describe a specific trigger using no more than 30 words.)";
        }
        setStep(1);
        break;
      case 1:
        setTopic(input);
        botText =
          "When you think about '" + input + "', what emotion do you feel?";
        if (processTipsShow) {
          botText += "\n(Describe the emotion using 1 word only)";
        }
        setStep(2);
        break;
      case 2:
        if (input.indexOf(" ") !== -1) {
          botText =
            "Please describe your emotion using 1 word only. For instance: sadness, frustration, fear, worry, and so on. Let’s try again. When you think about '" +
            topic +
            "', what emotion do you feel? ";
        } else {
          setEmotion(input);
          botText =
            "Now feel yourself… feel your identity… what identity is that… what kind of person are you?";
          setStep(3);
        }
        break;
      case 3:
        setIdentity(input);
        if (checkNothing(input)) {
          botText =
            "Now let’s check in. When you think about '" +
            topic +
            "', what emotion do you feel now?";
          setStep(5);
        } else {
          botText =
            "From the point of view of '" +
            input +
            "', what is your need or intention?";
          setStep(4);
        }
        break;
      case 4:
        setGoal(input);
        if (checkNothing(input)) {
          botText =
            "Now let’s check in. When you think about '" +
            topic +
            "', what emotion do you feel now?";
          setStep(5);
        } else {
          botText = "As you feel '" + input + "', what comes up now?";
          setStep(44);
        }
        break;
      case 44:
        botText =
          "Now feel yourself… feel your identity… what identity is that… what kind of person are you?";
        setStep(3);
        break;
      case 5:
        if (await checkPositiveEmotion(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Great! We are done";
            save = 1;
          }
        } else {
          setEmotion(input);
          botText =
            "Now feel yourself… feel your identity… what identity is that… what kind of person are you?";
          setStep(3);
        }
        break;
      case 2221:
        botText = integrationQues[1].replaceAll("[TRIGGER]", topic);
        setStep(2222);
        break;
      case 2222:
        botText = integrationQues[2].replaceAll("[TRIGGER]", topic);
        setStep(2223);
        break;
      case 2223:
        botText = integrationQues[3].replaceAll("[TRIGGER]", topic);
        setStep(2224);
        break;
      case 2224:
        botText = integrationQues[4].replaceAll("[TRIGGER]", topic);
        setStep(2225);
        break;
      case 2225:
        botText = "Great! We are done";
        save = 1;
        break;
    }
    return { botText, save };
  };

  const handleIpBasic = async (input: string) => {
    let cnt = 0;
    let save = 0;
    let eCnt = 0;
    let botText = "";

    switch (step) {
      case 0:
        botText = "What trigger would you like to work on?";
        if (processTipsShow) {
          botText +=
            "\n(Please describe a specific trigger using no more than 30 words.)";
        }
        setStep(1);
        break;
      case 1:
        setTopic(input);
        botText =
          "When you think about '" + input + "', what emotion do you feel?";
        if (processTipsShow) {
          botText += "\n(Describe the emotion using 1 word only)";
        }
        setStep(2);
        break;
      case 2:
        if (input.indexOf(" ") !== -1) {
          botText =
            "Please describe your emotion using 1 word only. For instance: sadness, frustration, fear, worry, and so on. Let’s try again. When you think about '" +
            topic +
            "', what emotion do you feel? ";
        } else {
          let tmp = input;
          if (checkYes(input)) tmp = emotion;
          else setEmotion(input);

          botText = "Feel '" + tmp + "' and tell me, what's bad about it?";
          setStep(3);
        }
        break;
      case 3:
        if (checkNothing(input)) {
          botText =
            "Feel '" +
            emotion +
            "' and tell me, what's good about it, even if it’s just a little bit?";
          setStep(4);
          setTipAdded(false);
        } else {
          botText =
            "Feel '" +
            emotion +
            "' and tell me, is there something else bad about it?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 4:
        if (checkNothing(input)) {
          botText =
            "Now shift your attention from '" +
            emotion +
            "' to yourself. Feel your identity. What kind of person are you being as you experience '" +
            emotion +
            "'?";
          setStep(5);
          setTipAdded(false);
        } else {
          botText =
            "Feel '" +
            emotion +
            "' and tell me, is there something else good about it?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 5:
        setIdentity(input);
        botText =
          "Feel yourself as '" +
          input +
          "', and tell me, what need or intention do you have?";
        setStep(6);
        break;
      case 6:
        if (checkNothing(input)) {
          botText =
            "Feel yourself as '" +
            identity +
            "', and tell me, what is a belief or conclusion you have?";
          setStep(7);
          setTipAdded(false);
        } else {
          botText =
            "From the point of view of '" +
            identity +
            "', do you have any other needs or intentions?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 7:
        if (checkNothing(input)) {
          botText =
            "If '" +
            identity +
            "' vanished, what’s something bad that could happen as a result?";
          setStep(8);
          setTipAdded(false);
        } else {
          botText =
            "From the point of view of '" +
            identity +
            "', do you have any other beliefs or conclusions?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 8:
        if (checkNothing(input)) {
          botText =
            "If '" +
            identity +
            "' vanished, what’s something good that could happen as a result?";
          setStep(9);
          setTipAdded(false);
        } else {
          botText =
            "If '" +
            identity +
            "' vanished, is there something else bad that could happen?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 9:
        if (checkNothing(input)) {
          botText =
            "Now let’s check in. When you think about '" +
            topic +
            "', what emotion do you feel now?";
          setStep(10);
          setTipAdded(false);
        } else {
          botText =
            "If '" +
            identity +
            "' vanished, is there something else good that could happen?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 10:
        if (await checkPositiveEmotion(input)) {
          botText =
            "Is there anything else about this that still feels like a problem for you?";
          setStep(11);
        } else {
          setEmotion(input);
          botText = "Would you like to start a new process on '" + input + "'?";
          setStep(12);
        }
        if (processTipsShow) {
          botText += "\n(Please type 'yes' or 'no')";
        }
        break;
      case 11:
        if (checkYes(input)) {
          botText = "Would you like to start a new process?";
          if (processTipsShow) {
            botText += "\n(Please type 'yes' or 'no')";
          }
          setStep(13);
        } else if (checkNo(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Great! We are done";
            save = 1;
          }
        }
        break;
      case 12:
        if (checkYes(input)) {
          botText =
            "Feel '" + emotion + "' and tell me, what's bad about that?";
          setStep(3);
        } else if (checkNo(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Okay! We are done for now";
            save = 1;
          }
        }
        break;
      case 13:
        if (checkYes(input)) {
          botText = "Okay, please describe the problem now";
          setStep(1);
        } else if (checkNo(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Okay! We are done for now";
            save = 1;
          }
        }
        break;
      case 2221:
        botText = integrationQues[1].replaceAll("[TRIGGER]", topic);
        setStep(2222);
        break;
      case 2222:
        botText = integrationQues[2].replaceAll("[TRIGGER]", topic);
        setStep(2223);
        break;
      case 2223:
        botText = integrationQues[3].replaceAll("[TRIGGER]", topic);
        setStep(2224);
        break;
      case 2224:
        botText = integrationQues[4].replaceAll("[TRIGGER]", topic);
        setStep(2225);
        break;
      case 2225:
        botText = "Great! We are done";
        save = 1;
        break;
    }
    return { botText, save };
  };

  const handleIpSimplified = async (input: string) => {
    let cnt = 0;
    let save = 0;
    let eCnt = 0;
    let botText = "";

    switch (step) {
      case 0:
        botText = "What trigger would you like to work on?";
        if (processTipsShow) {
          botText +=
            "\n(Please describe a specific trigger using no more than 30 words.)";
        }
        setStep(1);
        break;
      case 1:
        setTopic(input);
        botText =
          "When you think about '" + input + "', what emotion do you feel?";
        if (processTipsShow) {
          botText += "\n(Describe the emotion using 1 word only)";
        }
        setStep(2);
        break;
      case 2:
        if (input.indexOf(" ") !== -1) {
          botText =
            "Please describe your emotion using 1 word only. For instance: sadness, frustration, fear, worry, and so on. Let’s try again. When you think about '" +
            topic +
            "', what emotion do you feel? ";
        } else {
          let tmp = input;
          if (checkYes(input)) tmp = emotion;
          else setEmotion(input);

          botText = "Feel '" + tmp + "' and tell me, what's bad about it?";
          setStep(3);
        }
        break;
      case 3:
        if (checkNothing(input)) {
          botText =
            "Feel '" +
            emotion +
            "' and tell me, what's good about it, even if it’s just a little bit?";
          setStep(4);
          setTipAdded(false);
        } else {
          botText =
            "Feel '" +
            emotion +
            "' and tell me, is there something else bad about it?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 4:
        if (checkNothing(input)) {
          botText =
            "Feel '" +
            emotion +
            "', and tell me, what need or intention do you have?";
          setStep(5);
          setTipAdded(false);
        } else {
          botText =
            "Feel '" +
            emotion +
            "' and tell me, is there something else good about it?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 5:
        if (checkNothing(input)) {
          botText =
            "Feel '" +
            emotion +
            "', and tell me, what is a belief or conclusion you have?";
          setStep(6);
          setTipAdded(false);
        } else {
          botText =
            "Feel '" +
            emotion +
            "', and tell me, do you have any other needs or intentions?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 6:
        if (checkNothing(input)) {
          botText =
            "If '" +
            emotion +
            "' vanished, what’s something bad that could happen as a result?";
          setStep(7);
          setTipAdded(false);
        } else {
          botText =
            "Feel '" +
            emotion +
            "', and tell me, do you have any other beliefs or conclusions?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 7:
        if (checkNothing(input)) {
          botText =
            "If '" +
            emotion +
            "' vanished, what’s something good that could happen as a result?";
          setStep(8);
          setTipAdded(false);
        } else {
          botText =
            "If '" +
            emotion +
            "' vanished, is there something else bad that could happen?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 8:
        if (checkNothing(input)) {
          botText =
            "Now let’s check in. When you think about '" +
            topic +
            "', what emotion do you feel now?";
          setStep(10);
          setTipAdded(false);
        } else {
          botText =
            "If '" +
            emotion +
            "' vanished, is there something else good that could happen?";

          if (processTipsShow && !tipAdded) {
            botText += "\n(Type 'no' when complete)";
            setTipAdded(true);
          }
        }
        break;
      case 10:
        if (await checkPositiveEmotion(input)) {
          botText =
            "Is there anything else about this that still feels like a problem for you?";
          setStep(11);
        } else {
          setEmotion(input);
          botText = "Would you like to start a new process on '" + input + "'?";
          setStep(12);
        }
        if (processTipsShow) {
          botText += "\n(Please type 'yes' or 'no')";
        }
        break;
      case 11:
        if (checkYes(input)) {
          botText = "Would you like to start a new process?";
          if (processTipsShow) {
            botText += "\n(Please type 'yes' or 'no')";
          }
          setStep(13);
        } else if (checkNo(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Great! We are done";
            save = 1;
          }
        }
        break;
      case 12:
        if (checkYes(input)) {
          botText =
            "Feel '" + emotion + "' and tell me, what's bad about that?";
          setStep(3);
        } else if (checkNo(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Okay! We are done for now";
            save = 1;
          }
        }
        break;
      case 13:
        if (checkYes(input)) {
          botText = "Okay, please describe the problem now";
          setStep(1);
        } else if (checkNo(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Okay! We are done for now";
            save = 1;
          }
        }
        break;
      case 2221:
        botText = integrationQues[1].replaceAll("[TRIGGER]", topic);
        setStep(2222);
        break;
      case 2222:
        botText = integrationQues[2].replaceAll("[TRIGGER]", topic);
        setStep(2223);
        break;
      case 2223:
        botText = integrationQues[3].replaceAll("[TRIGGER]", topic);
        setStep(2224);
        break;
      case 2224:
        botText = integrationQues[4].replaceAll("[TRIGGER]", topic);
        setStep(2225);
        break;
      case 2225:
        botText = "Great! We are done";
        save = 1;
        break;
    }
    return { botText, save };
  };

  const handleDp2 = async (input: string) => {
    let cnt = 0;
    let save = 0;
    let eCnt = 0;
    let botText = "";

    switch (step) {
      case 0:
        botText = "What trigger would you like to work on?";
        if (processTipsShow) {
          botText +=
            "\n(Please describe a specific trigger using no more than 30 words.)";
        }
        setStep(1);
        break;
      case 1:
        setTopic(input);
        botText =
          "When you think about '" + input + "', what emotion do you feel?";
        if (processTipsShow) {
          botText += "\n(Describe the emotion using 1 word only)";
        }
        setStep(2);
        break;
      case 2:
        if (input.indexOf(" ") !== -1) {
          botText =
            "Please describe your emotion using 1 word only. For instance: sadness, frustration, fear, worry, and so on. Let’s try again. When you think about '" +
            topic +
            "', what emotion do you feel? ";
        } else {
          setEmotion(input);
          botText = "Feel... '" + input + "'. What image or thought is there?";
          setStep(3);
        }
        break;
      case 3:
        if (await checkPositiveEmotion(input)) cnt = positiveCnt + 1;
        else cnt = 0;
        setPositiveCnt(cnt);

        if (cnt === 3) {
          botText =
            "Now When you think about... '" +
            topic +
            "', what emotion do you feel?";
          setStep(5);
          setPositiveCnt(0);
        } else {
          botText =
            "Think or see... '" +
            input +
            "'. What emotion or body sensation is there?";
          setStep(4);
        }
        break;
      case 4:
        if (await checkPositiveEmotion(input)) cnt = positiveCnt + 1;
        else cnt = 0;
        setPositiveCnt(cnt);

        if (cnt === 3) {
          botText =
            "Now when you think about... '" +
            topic +
            "', what emotion do you feel?";
          setStep(5);
          setPositiveCnt(0);
        } else {
          botText = "Feel... '" + input + "'. What thought or image is there?";
          setStep(3);
        }
        break;
      case 5:
        if (await checkPositiveEmotion(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Great! We are done";
            save = 1;
          }
        } else {
          setEmotion(input);
          botText =
            "Feel... '" +
            input +
            "' and tell me, what is the next thought or image that comes up?";
          setStep(3);
        }
        break;
      case 2221:
        botText = integrationQues[1].replaceAll("[TRIGGER]", topic);
        setStep(2222);
        break;
      case 2222:
        botText = integrationQues[2].replaceAll("[TRIGGER]", topic);
        setStep(2223);
        break;
      case 2223:
        botText = integrationQues[3].replaceAll("[TRIGGER]", topic);
        setStep(2224);
        break;
      case 2224:
        botText = integrationQues[4].replaceAll("[TRIGGER]", topic);
        setStep(2225);
        break;
      case 2225:
        botText = "Great! We are done";
        save = 1;
        break;
    }

    return { botText, save };
  };

  const handleUniversal = async (input: string) => {
    let cnt = 0;
    let save = 0;
    let eCnt = 0;
    let botText = "";

    switch (step) {
      case 0:
        botText = "What trigger would you like to work on?";
        if (processTipsShow) {
          botText +=
            "\n(Please describe a specific trigger using no more than 30 words.)";
        }
        setStep(1);
        break;
      case 1:
        setTopic(input);
        botText =
          "When you think about '" +
          input +
          "', what negative emotion comes up?";
        if (processTipsShow) {
          botText += "\n(Describe the emotion using 1 word only)";
        }
        setStep(2);
        break;
      case 2:
        if (input.indexOf(" ") !== -1) {
          botText =
            "Please describe your emotion using 1 word only. For instance: sadness, frustration, fear, worry, and so on. Let’s try again. When you think about '" +
            topic +
            "', what emotion do you feel? ";
        } else {
          setEmotion(input);
          botText =
            "Instead of '" +
            input +
            "', what emotion would you like to feel instead?";
          setStep(3);
        }
        break;
      case 3:
        if (input.indexOf(" ") !== -1) {
          botText =
            "Please describe your emotion using 1 word only. For instance: sadness, frustration, fear, worry, and so on. Let’s try again. When you think about '" +
            topic +
            "', what emotion do you feel? ";
        } else {
          setEmotion2(input);
          botText = "Feel '" + emotion + "',  and tell me what comes up.";
          setStep(4);
        }
        break;
      case 4:
        botText = "Feel '" + emotion2 + "',  and tell me what comes up.";
        setStep(5);
        break;
      case 5:
        botText = "Feel '" + emotion + "',  and tell me what comes up.";
        setStep(6);
        break;
      case 6:
        botText = "Feel '" + emotion2 + "',  and tell me what comes up.";
        setStep(7);
        break;
      case 7:
        botText = "Feel '" + emotion + "',  and tell me what comes up.";
        setStep(8);
        break;
      case 8:
        botText = "Feel '" + emotion2 + "',  and tell me what comes up.";
        setStep(9);
        break;
      case 9:
        botText =
          "Now feel '" +
          emotion +
          "' and '" +
          emotion2 +
          "' at the same time. And as you feel them at the same time, take a deep inhale and exhale.";

        if (processTipsShow) botText += "\n(Type 'done' when you're done)";

        setStep(10);
        break;
      case 10:
        if (checkDone(input)) {
          botText =
            "Can you feel '" +
            emotion +
            "' separate from '" +
            emotion2 +
            "' or no?";

          if (processTipsShow) {
            botText += "\n(Please type 'yes' or 'no')";
          }
          setStep(11);
        } else {
          botText = "";
        }
        break;
      case 11:
        if (checkYes(input)) {
          botText = "Feel '" + emotion + "',  and tell me what comes up.";
          setStep(4);
        } else if (checkNo(input)) {
          botText =
            "Now let’s check in. When you think about '" +
            topic +
            "', what emotion do you feel now?";
          setStep(12);
        } else {
          botText =
            "Can you feel '" +
            emotion +
            "' separate from '" +
            emotion2 +
            "' or no?";

          if (processTipsShow) {
            botText += "\n(Please type 'yes' or 'no')";
          }
        }
        break;
      case 12:
        if (await checkPositiveEmotion(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Great! We are done";
            save = 1;
          }
        } else {
          setEmotion(input);
          botText =
            "Instead of '" +
            input +
            "', what emotion would you like to feel instead?";
          setStep(3);
        }
        break;
      case 2221:
        if (user.iqEnable) {
          botText = integrationQues[1].replaceAll("[TRIGGER]", topic);
          setStep(2222);
        } else {
          botText = "Great! We are done";
          save = 1;
        }
        break;
      case 2222:
        if (user.iqEnable) {
          botText = integrationQues[2].replaceAll("[TRIGGER]", topic);
          setStep(2223);
        } else {
          botText = "Great! We are done";
          save = 1;
        }
        break;
      case 2223:
        if (user.iqEnable) {
          botText = integrationQues[3].replaceAll("[TRIGGER]", topic);
          setStep(2224);
        } else {
          botText = "Great! We are done";
          save = 1;
        }
        break;
      case 2224:
        if (user.iqEnable) {
          botText = integrationQues[4].replaceAll("[TRIGGER]", topic);
          setStep(2225);
        } else {
          botText = "Great! We are done";
          save = 1;
        }
        break;
      case 2225:
        botText = "Great! We are done";
        save = 1;
        break;
    }
    return { botText, save };
  };

  const handle10f = (input: string) => {
    let cnt = 0;
    let save = 0;
    let eCnt = 0;
    let botText = "";

    switch (step) {
      case 0:
        if (
          user &&
          user.goal &&
          user.deadline &&
          user.goal !== "" &&
          user.deadline !== ""
        ) {
          botText =
            "Is your goal still " + user.goal + " by " + user.deadline + "?";
          if (user.processTips) botText += "\n(Please type 'yes' or 'no')";
          setStep(111);
        } else {
          setStep(1);
          botText = "What is your goal?";
        }
        break;
      case 111:
        if (checkYes(input)) {
          botText =
            "Think about achieving..." +
            goal +
            "...before " +
            deadline +
            ". Is it possible for you to fail?";
          if (processTipsShow) botText += "\n(Please type 'yes' or 'no')";
          setStep(3);
        } else {
          botText = "What is your goal?";
          setStep(1);
        }
        break;
      case 1:
        setGoal(input);
        dispatch(saveGoal_10F(input));
        botText = "When is your deadline for achievement of that goal?";
        setStep(2);
        break;
      case 2:
        setDeadline(input);
        dispatch(saveDeadline_10F(input));
        botText =
          "Think about achieving..." +
          goal +
          "...before " +
          input +
          ". Is it possible for you to fail?";
        if (processTipsShow) botText += "\n(Please type 'yes' or 'no')";
        setStep(3);
        break;
      case 3:
        if (checkYes(input)) {
          botText = "How could you fail?";
          setStep(4);
        } else if (checkNo(input)) {
          botText = "Great! We are done";
          save = 1;
        }
        break;
      case 4:
        setFailReason(input);
        botText = "Feel it’s possible to fail because " + input + ".";
        if (processTipsShow)
          botText += "\n(Inhale and exhale and type 'done' when done)";
        setStep(5);
        break;
      case 5:
        if (checkDone(input)) {
          botText =
            "Even though " + failReason + "..., do I still want " + goal + "?";
          if (processTipsShow)
            botText += "\n(Type 'yes' or 'no', then inhale and exhale)";
          setStep(6);
        }
        break;
      case 6:
        if (checkYes(input)) {
          botText = "Feel it’s possible to fail because " + failReason + ".";
          if (processTipsShow)
            botText += "\n(Inhale and exhale and type 'done' when done)";
          setStep(7);
        } else if (checkNo(input)) {
          botText = "Would you like to change your goal?";
          setStep(61);
        }
        break;
      case 61:
        if (checkYes(input)) {
          botText = "What is your goal?";
          setStep(1);
        } else if (checkNo(input)) {
          botText =
            "Think about " +
            goal +
            " before " +
            deadline +
            ". Is it possible for you to fail?";
          if (processTipsShow) botText += "\n(Please type 'yes' or 'no')";
          setStep(3);
        }
        break;
      case 7:
        if (checkDone(input)) {
          botText =
            "Even though... " +
            failReason +
            "... feel still wanting... " +
            goal;
          if (processTipsShow)
            botText += "\n(Inhale and exhale and type 'done' when done)";
          setStep(8);
        }
        break;
      case 8:
        if (checkDone(input)) {
          botText = "Feel it’s possible to fail because " + failReason + ".";
          if (processTipsShow)
            botText += "\n(Inhale and exhale and type 'done' when done)";
          setStep(9);
        }
        break;
      case 9:
        if (checkDone(input)) {
          botText =
            "Even though... " +
            failReason +
            "... feel still wanting... " +
            goal;
          if (processTipsShow)
            botText += "\n(Inhale and exhale and type 'done' when done)";
          setStep(10);
        }
        break;
      case 10:
        if (checkDone(input)) {
          botText = "Feel it’s possible to fail because " + failReason + ".";
          if (processTipsShow)
            botText += "\n(Inhale and exhale and type 'done' when done)";
          setStep(11);
        }
        break;
      case 11:
        if (checkDone(input)) {
          botText =
            "Even though... " +
            failReason +
            "... feel still wanting... " +
            goal;
          if (processTipsShow)
            botText += "\n(Inhale and exhale and type 'done' when done)";
          setStep(12);
        }
        break;
      case 12:
        if (checkDone(input)) {
          botText =
            "What insights, if anything, did you gain for achieving your goal?";
          setStep(13);
        }
        break;
      case 13:
        botText =
          "What insights, if anything, did you gain for improving your goal or defining it better?";
        setStep(14);
        break;
      case 14:
        botText =
          "Think about achieving..." +
          goal +
          "...before " +
          deadline +
          ". Is it possible for you to fail?";
        if (processTipsShow) botText += "\n(Please type 'yes' or 'no')";
        setStep(3);
        break;
    }
    return { botText, save };
  };

  const handleAspect = async (input: string) => {
    let cnt = 0;
    let save = 0;
    let eCnt = 0;
    let botText = "";

    switch (step) {
      case 0:
        botText = "What problem would you like to work on?";
        if (processTipsShow) {
          botText +=
            "\n(Please describe your problem using no more than 10 words)";
        }
        setStep(2);
        break;
      case 2:
        if ((input.match(/ /g) || []).length > 10) {
          botText = "Please describe your problem using no more than 10 words";
        } else {
          setTopic(input);
          botText =
            "Now think of a time when you recently felt... " +
            input +
            ". Enter that experience as if it’s happening right now. See what you saw then. Hear what you heard then. Feel what you felt then. Notice what you noticed then. Where do you feel this problem most strongly in your body?";
          setStep(4);
        }
        break;
      case 4:
        botText =
          "Good... Now what is its shape? Just use your imagination and tell me what comes to your mind.";
        setStep(5);
        break;
      case 5:
        botText = "How big is it?";
        setStep(6);
        break;
      case 6:
        botText = "What is its color?";
        setStep(7);
        break;
      case 7:
        botText = "How heavy is it?";
        setStep(8);
        break;
      case 8:
        botText = "How old is it?";
        setStep(9);
        break;
      case 9:
        botText = "On a scale of 1-10, how strong is it?";
        setStep(10);
        break;
      case 10:
        botText =
          "Good... Now I want you to address this Aspect of you in your mind. Say to it, “I know you wish me well because you are a part of me.”";
        if (processTipsShow) {
          botText += "\n(Let me know when you do that)";
        }
        setStep(11);
        break;
      case 11:
        botText =
          'Now ask your Aspect, "What goal do you wish to achieve for me?” and tell me the first answer that rises in your awareness';

        setStep(12);
        break;
      case 12:
        if ((input.match(/ /g) || []).length > 15) {
          botText = "Please restate the goal in 15 words or less";
          break;
        }

        setInputDisable(true);
        let rewordedGoal = await checkGoalSpiritualState(input);
        rewordedGoal = rewordedGoal.replace(/(\r\n|\n|\r)/gm, "");
        setInputDisable(false);
        if (rewordedGoal == "SPIRITUAL STATE") {
          botText =
            "How would you describe this state of no goals… emptiness? Nothingness? Light? Silence? Stillness?";
          if (processTipsShow) {
            botText += "\n(Describe it as best as you can in just 1 word.)";
          }
          setStep(16);
        } else {
          setAspectGoals([...aspectGoals, rewordedGoal]);
          botText =
            "Now imagine and feel your aspect’s goal of... " +
            rewordedGoal +
            "... already achieved. See it and experience it as 100% attained, and tell me when you are ready.";
          setStep(13);
        }
        break;

      case 13:
        if ((input.match(/ /g) || []).length > 10) {
          botText = "Please restate the goal in 10 words or less";
          break;
        }
        botText =
          "Now stay in this state of your goal being realized and ask your Aspect, “What goal do you want to achieve for me now which is even HIGHER?” Tell me the first answer that comes up";
        setStep(12);
        break;
      case 16:
        if ((input.match(/ /g) || []).length > 1) {
          botText = "Please describe this state using 1 word only";
          break;
        }
        setAspectHighestGoal(input);
        botText =
          "Now enter that " +
          input +
          "... and feel it completely. Merge with it. Become one with it. Identify with it. You should know this " +
          aspectHighestGoal +
          " is the deepest and truest essence of your being. Dive into it. Rest in it. Do that and tell me when you are ready.";
        setStep(17);
        break;
      case 17:
        botText =
          "Now let that " +
          aspectHighestGoal +
          " flow out, emanating in all directions. Let it cascade out in front of you, spreading infinitely up… down.. To the left… to the right.. In front of you.. And behind you. Do that and tell me when you are ready.";
        setStep(18);
        break;
      case 2001:
        botText =
          "Now let the " +
          aspectHighestGoal +
          " permeate your entire body. From your head to toe. To every cell of your body. Let it pass several times up and down through you. To rinse and cleanse you completely. Do that and tell me when you are ready";
        setStep(2002);
        break;

      case 2002:
        setAspectGoals([]);
        setAspectHighestGoal("");
        botText =
          "Is there anything else about... " +
          topic +
          "... that still feels like a problem for you?";
        setStep(2003);
        break;

      case 2003:
        if (checkNo(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Great! We are done";
            save = 1;
          }
        } else {
          botText = "Would you like to start a new process?";
          setStep(2004);
        }
        break;

      case 2004:
        if (checkNo(input)) {
          if (user.iqEnable) {
            botText = integrationQues[0].replaceAll("[TRIGGER]", topic);
            setStep(2221);
          } else {
            botText = "Great! We are done";
            save = 1;
          }
        } else {
          botText = "What problem would you like to work on?";
          if (processTipsShow) {
            botText +=
              "\n(Please describe your problem using no more than 10 words)";
          }
          setStep(2);
        }
        break;

      default:
        console.log("defaultstep", step);
        console.log("18 + aspectGoals.length", 18 + aspectGoals.length);

        if (step >= 18 && step <= 18 + aspectGoals.length) {
          if (step == 18 + aspectGoals.length) {
            console.log("defaultstep1", step);
            botText =
              "Now tell me, from the point of view of " +
              aspectHighestGoal +
              " as the truest and deepest essence of your being, how do you feel now about... " +
              topic +
              "?";
            setStep(2001);
          } else {
            console.log("defaultstep2", step);
            // Start from last index of goals
            botText =
              "Now tell me, from the point of view of " +
              aspectHighestGoal +
              " as the truest and deepest essence of your being, how do you feel now about... " +
              aspectGoals[aspectGoals.length - (step - 18) - 1] +
              "?";

            setStep(step + 1);
          }
        }
        break;

      case 2221:
        if (user.iqEnable) {
          botText = integrationQues[1].replaceAll("[TRIGGER]", topic);
          setStep(2222);
        } else {
          botText = "Great! We are done";
          save = 1;
        }
        break;
      case 2222:
        if (user.iqEnable) {
          botText = integrationQues[2].replaceAll("[TRIGGER]", topic);
          setStep(2223);
        } else {
          botText = "Great! We are done";
          save = 1;
        }
        break;
      case 2223:
        if (user.iqEnable) {
          botText = integrationQues[3].replaceAll("[TRIGGER]", topic);
          setStep(2224);
        } else {
          botText = "Great! We are done";
          save = 1;
        }
        break;
      case 2224:
        if (user.iqEnable) {
          botText = integrationQues[4].replaceAll("[TRIGGER]", topic);
          setStep(2225);
        } else {
          botText = "Great! We are done";
          save = 1;
        }
        break;
      case 2225:
        botText = "Great! We are done";
        save = 1;
        break;
    }
    return { botText, save };
  };

  const newUserInput = async (input: string) => {
    input = input.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
    //input = input.replace(/\s*$/, "");
    //input = input.replace(/\s\s+/g, " ");
    if (input === "" && step !=0) return;
    if (input != "")
      setChatHistory([...chatHistory, { isBot: false, text: input }]);
    forceUpdate();
    setSlowCnt(0);
    //await timeout(500);

    let cnt = 0;
    let botText = "";
    let save = 0;
    let eCnt = 0;

    if (isResume) {
      if (input.toLowerCase() === "restart") {
        await addInstructions(method);
        return;
      } else {
        botText = "";
      }
    } else if (isBugReport(input)) {
      API.saveChatLog({
        text: [...chatHistory, { isBot: false, text: input }],
        method: method,
        bug: 1,
      });
      botText = "Thank you for your feedback.\n" + prevQuestion;
    } else if (isRepeat(input)) {
      botText = prevQuestion;
    } else if ((input.match(/ /g) || []).length > 29 && step === 1) {
      if (
        prevQuestion.indexOf("Please keep your response to under 30 words") ===
        -1
      )
        botText =
          "Please keep your response to under 30 words.\n" + prevQuestion;
      else botText = prevQuestion;
    } else if (finished && input.toLowerCase() === "save") {
      let data = "";
      chatHistory.forEach((item) => {
        if (item.isBot) data += "Bot - " + item.text + "\n";
        else data += "User - " + item.text + "\n";
      });
      const blob = new Blob([data], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");

      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      let day = dateObj.getUTCDate();
      let year = dateObj.getUTCFullYear();
      let time = dateObj.getTime().toString();

      link.download =
        method + "_" + month + "_" + day + "_" + year + "_" + time + ".txt";
      link.href = url;
      link.click();
    } else {
      let res;
      switch (method) {
        case "satori1":
          res = handleSatori1(input);
          botText = res.botText;
          save = res.save;
          break;

        case "satori2":
          res = handleSatori2(input);
          botText = res.botText;
          save = res.save;
          break;
        case "satori3":
          res = handleSatori3(input);
          botText = res.botText;
          save = res.save;
          break;
        case "satori4":
          res = handleSatori4(input);
          botText = res.botText;
          save = res.save;
          break;

        case "dp2":
          res = await handleDp2(input);
          botText = res.botText;
          save = res.save;
          break;

        case "ip_standard":
          res = await handleIpStandard(input);
          botText = res.botText;
          save = res.save;
          break;

        case "universal":
          res = await handleUniversal(input);
          botText = res.botText;
          save = res.save;
          break;

        case "ip_advanced":
          res = await handleIpAdvanced(input);
          botText = res.botText;
          save = res.save;
          break;

        case "ip_basic":
          res = await handleIpBasic(input);
          botText = res.botText;
          save = res.save;
          break;

        case "ip_simplified":
          res = await handleIpSimplified(input);
          botText = res.botText;
          save = res.save;
          break;

        case "satori_trainer":
          res = handleSatoriTrainer(input);
          botText = res.botText;
          save = res.save;
          break;

        case "10f":
          res = handle10f(input);
          botText = res.botText;
          save = res.save;
          break;

        case "aspect":
          res = await handleAspect(input);
          botText = res.botText;
          save = res.save;
          break;
      }

      if ((input.match(/ /g) || []).length > 29)
        botText = "Please keep your response to under 30 words.\n" + botText;
    }

    console.log("save", save);

    if (botText === "") {

      setChatHistory([...chatHistory, { isBot: false, text: input }]);
    } else {
      if (save === 1) {
        if (processTipsShow)
          botText +=
            "\n(Type 'save' to download a transcript of this session.)";

        setNeedTimer(false);
      }

      setChatHistory([
        ...chatHistory,
        { isBot: false, text: input },
        { isBot: true, text: botText },
      ]);
      setPrevQuestion(botText);
      textToSpeech(botText);
    }

    if (save === 1) {
      setFinished(1);
      API.saveChatLog({
        text: [
          ...chatHistory,
          { isBot: false, text: input },
          { isBot: true, text: botText },
        ],
        method: method,
        bug: 0,
      });
    }
  };

  const timerFunc = () => {
    if (method.indexOf("satori") === -1) return;

    if (isResume || !needTimer) return;
    let botText;
    if (slowCnt === 3) {
      botText =
        "Are you still there? I'm going to pause our session now. When you are ready, type 'restart' to restart the process";
      setSlowCnt(0);
      setIsResume(true);
    } else {
      botText = "Your response is too slow. Lets try again. " + prevQuestion;
      setSlowCnt(slowCnt + 1);
    }

    setChatHistory([...chatHistory, { isBot: true, text: botText }]);
    textToSpeech(botText);
  };

  useEffect(() => {
    if (chatHistory.length > 0) scrollToBottom();
    if (!processTimer) return;

    const timeout = setTimeout(timerFunc, responseTime);
    setTimerObj(timeout);

    return () => {
      // clears timeout before running the new effect
      clearTimeout(timeout);
    };
  }, [chatHistory]);

  return (
    <StyledChat>
      <ChatSidebar />
      <StyledChatContent>
        <Scrollbar
          className="chat-history"
          ref={(c: any) => {
            setScrollBarObj(c);
          }}
        >
          {chatHistory.map((item, i) => {
            return (
              <StyledChatHistoryItem className="chat-history-item" key={i}>
                {item.isBot && (
                  <StyledChatLogo>
                    <LogoSvg width="30" height="30" className="chat-logo-svg" />
                  </StyledChatLogo>
                )}
                <StyledChatHistoryItemText
                  className={`chat-history-item-text${
                    item.isBot ? " chat-bot-text" : ""
                  }`}
                  dangerouslySetInnerHTML={{ __html: item.text }}
                ></StyledChatHistoryItemText>
              </StyledChatHistoryItem>
            );
          })}
        </Scrollbar>
        <StyledChatFooter>
          <ChatInput
            placeholder="Please input answer..."
            disabled={inputDisable}
            func={newUserInput}
            change={inputChange}
          />
        </StyledChatFooter>
      </StyledChatContent>
      <Modal show={purchaseModalShow} onHide={purchaseModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Get Access To All Protocols</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <StyledModalContent>
            <StyledModalText1>Join today and get access to:</StyledModalText1>

            <CheckText text="All 11 ET processes" />
            <CheckText text="Hands-free voice guidance" />
            <CheckText text="Unlimited sessions" />
            <CheckText text="Bonus trainings" />
            <CustomButton
              type="gradient"
              text="Join Today"
              target="_blank"
              href={"https://enlightenmenttherapy.co/etappfull"}
            />
          </StyledModalContent>
        </Modal.Body>
      </Modal>
    </StyledChat>
  );
}
